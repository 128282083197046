/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react/display-name */
import { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import LoadingUi from "../components/LoadingUi/LoadingUi";

// Loadable function
const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingUi />}>
      <Component {...props} />
    </Suspense>
  );
};


// Lazy-loaded components
const MainLayout = Loadable(lazy(() => import("../Layout/MainLayout")));
const Home = Loadable(lazy(() => import("../pages/Home/Home/Home")));
const Login = Loadable(lazy(() => import("../pages/Login/Login")));
const Register = Loadable(lazy(() => import("../pages/Register/Register")));
const Unauthorized = Loadable(lazy(() => import("../pages/Unauthorized/Unauthorized")));
const Insurance = Loadable(lazy(() => import("../pages/Insurance/Insurance")));
const VerifyToken = Loadable(lazy(() => import("../pages/Home/VerifyToken/VerifyToken")));
const Eligibility = Loadable(lazy(() => import("../pages/Eligibility/Eligibility")));
const Partner = Loadable(lazy(() => import("../pages/Partner/Partner")));
const About = Loadable(lazy(() => import("../pages/About/About")));
const Services = Loadable(lazy(() => import("../pages/Services/Services")));
const PolicyPages = Loadable(lazy(() => import("../pages/PolicyPages/PolicyPages")));
const TermsAndCondition = Loadable(
  lazy(() => import("../pages/TermsAndCinditions/TermsAndCondition"))
);

const DashboardPrivateRoute = Loadable(
  lazy(() => import("./DashboardPrivateRoute"))
);
const DashBoardLayout = Loadable(
  lazy(() => import("../Layout/DashBoardLayout"))
);
const AddProgram = Loadable(lazy(() => import("../pages/Dashboard/AddProgram/AddProgram")));
const AllPrograms = Loadable(lazy(() => import("../pages/Dashboard/AllPrograms/AllPRograms")));
const ProgramDetails = Loadable(lazy(() => import("../pages/Dashboard/AllPrograms/ProgramDetails")));
const UpdateProgram = Loadable(lazy(() => import("../pages/Dashboard/UpdateProgram/UpdateProgram")));
const StudentReq = Loadable(lazy(() => import("../pages/Dashboard/StudentReq/StudentReq")));
const StudentDetailsPage = Loadable(
  lazy(() => import("../pages/Dashboard/StudentDetailsPage/StudentDetailsPage"))
);
const CreateUniversity = Loadable(
  lazy(() => import("../pages/Dashboard/CreateUniversity/CreateUniversity"))
);
const AllUniversity = Loadable(lazy(() => import("../pages/Dashboard/University/AllUniversity")));
const UpdateUniversity = Loadable(
  lazy(() => import("../pages/Dashboard/University/UpdateUniversity"))
);
const CreateLanguage = Loadable(lazy(() => import("../pages/Dashboard/CreateLanguage/CreateLanguage")));
const AllLanguage = Loadable(lazy(() => import("../pages/Dashboard/Language/AllLanguage")));
const UpdateLanguage = Loadable(lazy(() => import("../pages/Dashboard/Language/UpdateLanguage")));
const AllSubject = Loadable(lazy(() => import("../pages/Dashboard/Subject/AllSubject")));
const AllExperience = Loadable(lazy(() => import("../pages/Dashboard/Experience/AllExperience")));

const StudentPrivateRoute = Loadable(
  lazy(() => import("./StudentPrivateRoute"))
);
const StudentProfile = Loadable(lazy(() => import("../Layout/StudentProfile")));
const ManageAccount = Loadable(lazy(() => import("../pages/StudentProfile/ManageAccount/ManageAccount")));
const UpdateAccount = Loadable(lazy(() => import("../pages/StudentProfile/UpdateAccount/UpdateAccount")));
const PersonalProfile = Loadable(
  lazy(() => import("../pages/StudentProfile/PersonalProfile/PersonalProfile"))
);
const UpdateProfile = Loadable(lazy(() => import("../pages/StudentProfile/PersonalProfile/UpdateProfile")));

// Define routes
const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/sign-in", element: <Login /> },
      { path: "sign-up", element: <Register /> },
      { path: "unauthorized", element: <Unauthorized /> },
      { path: "insurance", element: <Insurance /> },
      { path: "verify-email", element: <VerifyToken /> },
      { path: "eligibility", element: <Eligibility /> },
      { path: "partner", element: <Partner /> },
      { path: "about", element: <About /> },
      { path: "service", element: <Services /> },
      { path: "policy", element: <PolicyPages /> },
      { path: "termsAndConditions", element: <TermsAndCondition /> },
    ],
  },
  {
    path: "/dashboard",
    element: <DashboardPrivateRoute element={<DashBoardLayout />} />,
    children: [
      { path: "addProgram", element: <AddProgram /> },
      { path: "allPrograms", element: <AllPrograms /> },
      { path: "programDetails/:id", element: <ProgramDetails /> },
      { path: "updateProgram/:id", element: <UpdateProgram /> },
      { path: "studentRequest", element: <StudentReq /> },
      { path: "studentRequest/:id", element: <StudentDetailsPage /> },
      { path: "createUniversity", element: <CreateUniversity /> },
      { path: "allUniversity", element: <AllUniversity /> },
      { path: "updateUniversity/:uniId", element: <UpdateUniversity /> },
      { path: "createLanguage", element: <CreateLanguage /> },
      { path: "allLanguage", element: <AllLanguage /> },
      { path: "updateLanguage/:langId", element: <UpdateLanguage /> },
      { path: "allSubject", element: <AllSubject /> },
      { path: "allExperience", element: <AllExperience /> },
    ],
  },
  {
    path: "/student",
    element: <StudentPrivateRoute element={<StudentProfile />} />,
    children: [
      { path: "academic-profile", element: <ManageAccount /> },
      { path: "update-academic-profile", element: <UpdateAccount /> },
      { path: "personal-profile", element: <PersonalProfile /> },
      { path: "update-profile", element: <UpdateProfile /> },
    ],
  },
  // Add a catch-all route to handle 404 pages
  // { path: '*', element: <Navigate to="/404" replace /> },
]);

export default router;
