import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "sonner";
import { logout, setUser } from "../features/auth/authSlice";
import config from "../../config";

const baseQuery = fetchBaseQuery({
  baseUrl: config.baseUrl,
  // mode: "cors",
  credentials: "same-origin",
  prepareHeaders: (headers, { getState }) => {
    // console.log((getState()).auth.accessToken);
    
    const token = getState().auth.accessToken;
    
    

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithRefreshToken = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 404) {
    toast.error(result.error.data.errorMessage);
  }

  if (result?.error?.status === 401) {
    const refreshToken = api.getState().auth.refreshToken;

    if (refreshToken) {
      const res = await fetch(`${config.baseUrl}/token/refresh/`, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken }),
      });

      const data = await res.json();

      if (data?.data?.accessToken) {
        const user = api.getState().auth.user;

        api.dispatch(
          setUser({
            user,
            accessToken: data?.data?.accessToken,
            refreshToken: data?.data?.refreshToken,
          })
        );

        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(logout());
      }
    } else {
      api.dispatch(logout());
    }
  }

  return result;
};

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: baseQueryWithRefreshToken,
  endpoints: () => ({}),
  tagTypes: [
    "program",
    "profile",
    "applicant",
    "university",
    "language",
    "country",
    "city",
    "experience",
    "eligibility",
  ],
});
